import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import {Fade } from "react-reveal"
import { ImCheckmark, ImNotification } from "react-icons/im"
import axios from "axios"
import { SectionTitle } from "../Layout/SectionTitle";


const localText = {
  helperText: {
    en: "Please get in touch to discuss your needs and find right vessel for you.",
  },
}

const localFormFields = {
  name: {
    label: {
      en: "Your name",
    },
    errors: {
      required: {
        en: "This field is required",
      },
    },
  },
  email: {
    label: {
      en: "Email",
    },
    errors: {
      required: {
        en: "This field is required",
      },
      pattern: {
        en: "Please eneter valid email",
      },
    },
  },
  phone: {
    label: {
      en: "Mobile Phone Number",
    },
    errors: {
      required: {
        en: "This field is required",
      },
      pattern: {
        en: "Please eneter valid phone number",
      },
    },
  },
  projectAddress: {
    label: {
      en: "Project address",
    },
  },
  text: {
    label: {
      en: "Tell us about your project",
    },
    errors: {
      required: {
        en: "This field is required",
      },
    },
  },
  submit: {
    en: "Send it",
  },
}

const localSuccessMessage = {
  title: {
    en: "Thank you for contacting us!",
  },
  subtitle_1: {
    en: "We are processing your request.",
  },
  subtitle_2: {
    en: "You can expect to hear from our team shortly.",
  },
}
const localSuccessMessageOne = {
  title: {
    en: "We got your message",
  },
  subtitle_1: {
    en: "We'll be in touch soon to discuss your project in more details",
  },
  subtitle_2: {
    en: "In the meantime,feel free to browse our portfolio and get inspired by our previous work",
  },
  subtitle_3: {
    en: "We're confident that we can create a space that you'll absolutely love",
  },
  subtitle_4: {
    en: "<strong>Joseph Sacco & the Js Interiors Group team</strong>",
  },
}

const ContactForm = styled(({ className, lang, withSubText, subtitle, btnLabel }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [serverError, setServerError] = useState("")
  const [formSubmited, setFormSubmited] = useState(false)
  const validateEmail = {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Please enter a valid email",
  }
  const handleFormData = async (data) => {
    const fields = []
    for (let type in data) {
       if(type === "projectAddress") continue
        fields.push({
          value: data[type],
          type: type.toUpperCase(),
        })
    }
    if (data.projectAddress) {
        fields.push({
          value: data.projectAddress,
          type: "SUBJECT",
        })
      }
    const dataToSend = {
      identifier: process.env.WEBFRONT_ID,
      iso: "en",
      fields: fields,
    }
    try {
      const response = await axios.post(process.env.FORM_CONTACT_API_URL || "" ,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
          mode: 'cors',
          cache: 'no-cache',
          redirect: 'follow',
          referrer: 'no-referrer',
        }
      )
      setFormSubmited(true)
      setTimeout(() => {
        reset({
          name:"",
          phone: "",
          email:"",
          projectAddress: "",
          text: ""
        })
        setFormSubmited(false)
        // console.log(response)
      }, 20000)
    }catch(error) {
      if (error.response) {
        setServerError(error.response.data.message)
      } else if (error.request) {

        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
    }
  }
  return (
    <div style={{marginTop: `${formSubmited ? "-210px" : "initial"}`}} className={className}>
      {!formSubmited ? (
        <form onSubmit={handleSubmit(handleFormData)} method="POST">
          <div className="form-inputs">
            <div className="input-field">
              <label style={{
                color: errors.name ? "#A93E3E": "#b0b1b4"
              }} htmlFor="name">{localFormFields.name.label[lang]} {errors.name && "*"}</label>
              <input
                id="name"
                type="text"
                {...register("name", { required: true })}
                style={{
                  borderColor: errors.name ? "#A93E3E": "#b0b1b4"
                }}
              />
              {/*{errors.name && (*/}
              {/*  <span style={{ color: "red", fontSize: "13px" }}>*/}
              {/*        {localFormFields.name.errors.required[lang]}*/}
              {/*      </span>*/}
              {/*)}*/}
            </div>
            <div className="input-field">
              <label style={{
                color: errors.phone?.type === "required" ? "#A93E3E": "#b0b1b4"
              }} htmlFor="phone">{localFormFields.phone.label[lang]} {errors.phone?.type === "required" && "*"}</label>
              <input
                id="phone"
                type="number"
                {...register("phone", { required: true })}
                style={{
                  borderColor: errors.phone?.type === "required" ? "#A93E3E": "#b0b1b4"
                }}
              />
              {/*{errors.phone?.type === "required" && (*/}
              {/*  <span style={{ color: "red", fontSize: "13px" }}>*/}
              {/*      {localFormFields.phone.errors.required[lang]}*/}
              {/*    </span>*/}
              {/*)}*/}
              {/*{errors.phone?.type === "pattern" && (*/}
              {/*  <span style={{ color: "red", fontSize: "13px" }}>*/}
              {/*      {localFormFields.phone.errors.pattern[lang]}*/}
              {/*    </span>*/}
              {/*)}*/}
            </div>
            <div className="input-field">
              <label style={{
                color: errors.email?.type === 'required' ? "#A93E3E": "#b0b1b4"
              }}
                     htmlFor="email">{localFormFields.email.label[lang]} {errors.email?.type === "required" && "*"}</label>
              <input
                id="email"
                {...register("email", {
                  required: true,
                  pattern: validateEmail,
                })}
                type="email"
                style={{
                  borderColor: errors.email?.type === 'required' ? "#A93E3E": "#b0b1b4"
                }}
              />
              {/*{errors.email?.type === "required" && (*/}
              {/*  <span style={{ color: "red", fontSize: "13px" }}>*/}
              {/*      {localFormFields.email.errors.required[lang]}*/}
              {/*    </span>*/}
              {/*)}*/}
              {errors.email?.type === "pattern" && (
                <span style={{ color: "#A93E3E", fontSize: "13px" }}>
                    {localFormFields.email.errors.pattern[lang]}
                  </span>
              )}
            </div>
            <div className="input-field">
              <label htmlFor="projectAddress">{localFormFields.projectAddress.label[lang]}</label>
              <input
                type="text"
                id="projectAddress"
                {...register("projectAddress")}
              />
              {/*{errors.projectAddress && (*/}
              {/*  <span style={{ color: "red", fontSize: "13px" }}>*/}
              {/*        {localFormFields.projectAddress.errors.required[lang]}*/}
              {/*      </span>*/}
              {/*)}*/}
            </div>
            <div className="input-field fullHeight">
              <label  style={{
                color: errors.text ? "#A93E3E": "#b0b1b4"
              }} htmlFor="text">{localFormFields.text.label[lang]} {errors.text && "*"}</label>
                <textarea
                  id="text"
                  {...register("text", { required: true })}
                  cols="30"
                  rows="10"
                  style={{
                    border: errors.text ? "2px solid #A93E3E": " 2px solid #b0b1b4"
                  }}
                />
              {/*{errors.text && (*/}
              {/*  <span style={{ color: "red", fontSize: "13px" }}>*/}
              {/*      {localFormFields.text.errors.required[lang]}*/}
              {/*    </span>*/}
              {/*)}*/}
            </div>
            <div className="form-footer">
              {withSubText && <p>{ subtitle || localText.helperText[lang]}</p>}
              <button type="submit">
                {btnLabel || localFormFields.submit[lang]}
              </button>
            </div>
          </div>
        </form>
      ) : (
        <Fade bottom delay={300}>
          <div
            className="success-contact-form"
            style={{
              display: "flex",
              background: "white",
              color: "#161b1f",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "600px",
            }}
          >
            <div>
              {serverError ? (
                <div>
                  <div>
                    <ImNotification size="75px" color="red" />
                  </div>
                  <span
                    style={{
                      display: "inline-block",
                      marginTop: "30px",
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginBottom: "30px",
                    }}
                  >
                    {serverError}
                  </span>
                </div>
              ) : (
                <Fade bottom delay={500}>
                  <SectionTitle>
                    {localSuccessMessageOne.title[lang]}
                  </SectionTitle>
                  {/*<div>*/}
                  {/*  <ImCheckmark size="75px" color="green" />*/}
                  {/*</div>*/}

                  <p>{localSuccessMessageOne.subtitle_1[lang]} </p>
                  <p>{localSuccessMessageOne.subtitle_2[lang]}</p>
                  <p>{localSuccessMessageOne.subtitle_3[lang]}</p>
                  <br />
                  <p style={{margin:"0 auto"}}>Best regards,</p>
                  <p style={{margin: "0 auto", lineHeight: "14px"}} dangerouslySetInnerHTML={{__html: localSuccessMessageOne.subtitle_4[lang]}} />
                </Fade>
              )}
            </div>
          </div>
        </Fade>
      )}
    </div>
  )
})`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #77787a;
  padding:0 25px;
  .success-contact-form {
    p {
      line-height: 19px;
      max-width:62%;
      margin: 18px auto;
      letter-spacing: 0.8px;
      strong {
        font-weight:600;
      }
    }
  }
  form {
    width: 100%;
  }
  label {
    font-size:18px;
    font-weight: 400;
    line-height: 18.38px;
    color: #b0b1b4;
  }
  .form-inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: auto;
    border-radius: 0 !important;
  }

  .input-field {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    span {
      display: inline-block;
      padding:20px;
      font-size:17px !important;
    }
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 2px solid rgb(176 177 180);
    padding: 25px 10px 10px 10px;
    font-size:18px;
    border-radius: 0 !important;
    &:focus-visible {
      outline:none;
    }
  }

  textarea {
    width: 100%;
    height: 250px;
    @media screen and (min-width: 992px) {
      min-height:320px;
    }
    border-radius: 0 !important;
    border-color:rgb(176 177 180);
    margin-top:50px;
    padding:25px 10px;
    &:focus-visible {
      outline:none;
    }
  }
  
  button[type="submit"] {
    text-transform: uppercase;
    font-size:20px;
    border:none;
    background: transparent;
    &:hover {
      cursor: pointer;
    }
  }
`

export default ContactForm
