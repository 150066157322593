import styled from "styled-components"

export const FormWrapper = styled.div`
  width:100%;
  max-width:600px;
  padding-top:30px;
  margin:auto;
  position:relative;
  @media screen and (min-width: 600px) {
    position:absolute;
  }
  top:0;
  bottom: 0;
  background: #ffffff;
  left:50%;
  transform: translateX(-50%);
  z-index: 80;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
