import React from "react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { Container } from "../components/Layout/Container";
import { SectionTitle } from "../components/Layout/SectionTitle";
import { Section } from "../components/Layout/Section";
import TopBanner from "../components/TopBanner";
import { FormWrapper } from "../components/ContactForm/form-wrapper";
import ContactForm from "../components/ContactForm"
import { useResponsive } from "ahooks";
import { StaticImage } from "gatsby-plugin-image";

const Index = ({pageContext, data, path}) => {
    const responsive = useResponsive()
    const title = data.template.sections[0].items[0].locales.find(l => l.iso === pageContext.lang).value
    const bgImage = data.template.sections[0].items[1].imageFile?.publicURL
  // console.log('RESPONSIVE: ', responsive['md']);
  return (
    <Layout pathName={path} insidePage breadCrumbLinks={pageContext.breadCrumb}>
      <Container width="full-size">
          <Section>
            <SectionTitle>
              {title || "Contact us"}
            </SectionTitle>
          </Section>
      </Container>
      <Container width="full-size">
        {responsive && !responsive['md'] && <Section style={{marginBottom:"92px",overflowX: "hidden"}}>
            <StaticImage src="../images/contactTop.jpeg" />
          </Section> }
        <Section style={{marginTop:"52px",paddingBottom: responsive && !responsive['md'] ? "0" : "65px", height: responsive && !responsive['md'] ? "auto" : "100vh"}}>
          { responsive && responsive['md'] &&<TopBanner withOutOverlay insidePage image={bgImage}/> }
          <FormWrapper>
            <ContactForm lang={pageContext.lang} />
          </FormWrapper>
        </Section>
        {responsive && !responsive['md'] && <Section style={{marginTop:"92px", overflowX: "hidden"}}>
          <StaticImage src="../images/contactBottom.jpeg" />
        </Section> }
      </Container>
    </Layout>
  )
}

export const query = graphql`
    query($templateName: String) {
        template(name: {eq: $templateName}) {
            locales {
                iso
                metaDescription
                metaKeywords
                metaTitle
                value
            }
            sections {
                items {
                    key
                    ... on DataItemAssetGet {
                        __typename
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                            }
                            publicURL
                        }
                    }
                    ... on DataItemTextGet {
                        __typename
                        locales {
                            iso
                            value
                        }
                    }
                }
            }
        }
    }
`
export const Head = ({data, pageContext}) => {
  const { metaTitle, metaDescription} = data.template.locales.find(l => l.iso === pageContext.lang)
  return  <Seo title={metaTitle} description={metaDescription} />
}
export default Index
